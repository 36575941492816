@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

* {
  margin: 0;
  padding: 0;
  scroll-margin-top: 9vh;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white !important;
  background-color: rgba(255, 255, 255, 0.5);
  right: 10px;
  padding: 10px;
  color: #c297d3 !important;
  fill: #c297d3 !important;
  stroke: #c297d3 !important;
}

/* .image-gallery-icon {
    background-color: rgba(255, 255, 255, 0.5);
    right:10px;
    padding: 10px;
    color: #C297D3!important;
    fill: #C297D3 !important;
    stroke: #C297D3 !important;
} */

.image-gallery-image {
  width: 100vw !important;
  border-radius: 20px !important;
  box-sizing: none !important;
  margin-right: 10px;
  height: 50vh !important;
  object-fit: contain !important;
  border-radius: 20px;
  cursor: pointer;
}


/* ola */

.video {
  width: 80% !important;
  height: 100% !important;
  margin: auto;
}

.image-gallery-thumbnail {
  border-radius: 10px;
  object-fit: cover !important;
}

.image-gallery-thumbnail-image {
  border-radius: 7px;
  object-fit: cover !important;
  height: 100px !important;
}

.image-gallery-thumbnail:hover {
  border: 3px solid #fff !important;
  opacity: 50%;
}

.active {
  border: 3px solid black !important;
}

.MuiAccordionSummary-root {
  box-shadow: none !important;
  /* background-color: red !important; */
  border: none !important;
}

.MuiSlider-thumb {
  color: black;
}

.MuiSlider-rail {
  color: black;
}

.MuiSlider-track {
  color: black;
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
}

/* .react-carousel-responsive__control {
display:none
} */

.control-dots .dot {
  margin-bottom: 20px !important;
  background-color: white !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

.secondary-img {
  opacity: 0.7;
}

#hover-img {
  opacity: 1;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-image: url(./components/img/logo.png);
  background-size: 8em;
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

@keyframes scroller {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-400%);
  }
}

.scrollingContent {
  white-space: nowrap;
  animation-duration: 20s;
  animation-name: scroller;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
